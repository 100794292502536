import React from "react";
import PropTypes from 'prop-types';
import {useDropzone} from 'react-dropzone';
import {
    Box,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText, Stack, SvgIcon,
    Tooltip,
    Typography
} from '@mui/material';
import {Duplicate as DuplicateIcon} from 'src/omnia/icons/duplicate';
import {X as XIcon} from 'src/omnia/icons/x';
import {bytesToSize} from 'src/omnia/utils/bytesToSize';
import {useTranslation} from "react-i18next";
import UploadIcon from '@untitled-ui/icons-react/build/esm/UploadCloud02';

export const FileDropzone = (props) => {
    const {
        accept,
        // eslint-disable-next-line no-unused-vars
        disabled,
        files = [],
        // eslint-disable-next-line no-unused-vars
        getFilesFromEvent,
        maxFiles,
        maxSize,
        minSize,
        // eslint-disable-next-line no-unused-vars
        noClick,
        // eslint-disable-next-line no-unused-vars
        noDrag,
        // eslint-disable-next-line no-unused-vars
        noDragEventsBubbling,
        // eslint-disable-next-line no-unused-vars
        noKeyboard,
        onDrop,
        // eslint-disable-next-line no-unused-vars
        onDropAccepted,
        // eslint-disable-next-line no-unused-vars
        onDropRejected,
        // eslint-disable-next-line no-unused-vars
        onFileDialogCancel,
        onRemove,
        onRemoveAll,
        onUpload,
        // eslint-disable-next-line no-unused-vars
        preventDropOnDocument,
        showButtons,
        height,
        ...other
    } = props;

    // We did not add the remaining props to avoid component complexity
    // but you can simply add it if you need to.
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept,
        maxFiles,
        maxSize,
        minSize,
        onDrop
    });

    const {t} = useTranslation();

    return (
        <div {...other}>
            <Box
                sx={{
                    height: props.height,
                    width: '100%',
                    alignItems: 'center',
                    border: 1,
                    borderRadius: 1,
                    borderStyle: 'dashed',
                    borderColor: 'divider',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    outline: 'none',
                    p: 6,
                    ...(isDragActive && {
                        backgroundColor: 'action.active',
                        opacity: 0.5
                    }),
                    '&:hover': {
                        backgroundColor: 'action.hover',
                        cursor: 'pointer',
                        opacity: 0.5
                    }
                }}
                {...getRootProps()}>
                <input {...getInputProps()} />
                <Stack
                    direction="column"
                    alignItems="center"
                    spacing={2}
                >
                    <SvgIcon fontSize="large">
                        <UploadIcon />
                    </SvgIcon>
                    <Stack
                        direction="column"
                        alignItems="center"
                        spacing={0.5}
                    >
                        <Typography variant="h6">
                            {t("common.upload_files")}
                        </Typography>
                        <Typography variant="body1">
                            {t("common.select_files_info")}
                        </Typography>
                    </Stack>
                </Stack>
            </Box>
            {files.length > 0 && (
                <Box sx={{mt: 2, p: 1}}>
                    <List sx={{maxHeight: 300, overflow: 'auto', px: 1}}>
                        {files.map((file) => (
                            <ListItem
                                key={file.path}
                                sx={{
                                    border: 1,
                                    borderColor: 'divider',
                                    borderRadius: 1,
                                    '& + &': {
                                        mt: 1
                                    }
                                }}
                            >
                                <ListItemIcon>
                                    <DuplicateIcon fontSize="small"/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={file.name}
                                    primaryTypographyProps={{
                                        color: 'textPrimary',
                                        variant: 'subtitle2'
                                    }}
                                    secondary={bytesToSize(file.size)}
                                />
                                <Tooltip title={t("common.remove")}>
                                    <IconButton
                                        edge="end"
                                        onClick={() => onRemove?.(file)}
                                    >
                                        <XIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                            </ListItem>
                        ))}
                    </List>
                    {showButtons && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                mt: 2
                            }}
                        >
                            <Button
                                onClick={onRemoveAll}
                                size="small"
                                type="button"
                            >
                                {t("common.remove_all")}
                            </Button>
                            <Button
                                onClick={onUpload}
                                size="small"
                                sx={{ml: 2}}
                                type="button"
                                variant="contained"
                            >
                                {t("common.buttons.upload")}
                            </Button>
                        </Box>
                    )}
                </Box>
            )}
        </div>
    );
};

FileDropzone.defaultProps = {
    showButtons: true,
    height: 400
}

FileDropzone.propTypes = {
    accept: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string.isRequired)
    ]),
    disabled: PropTypes.bool,
    showButtons: PropTypes.bool,
    files: PropTypes.array,
    getFilesFromEvent: PropTypes.func,
    maxFiles: PropTypes.number,
    maxSize: PropTypes.number,
    minSize: PropTypes.number,
    noClick: PropTypes.bool,
    noDrag: PropTypes.bool,
    noDragEventsBubbling: PropTypes.bool,
    noKeyboard: PropTypes.bool,
    onDrop: PropTypes.func,
    onDropAccepted: PropTypes.func,
    onDropRejected: PropTypes.func,
    onFileDialogCancel: PropTypes.func,
    onRemove: PropTypes.func,
    onRemoveAll: PropTypes.func,
    onUpload: PropTypes.func,
    preventDropOnDocument: PropTypes.bool,
    height: PropTypes.number
};
