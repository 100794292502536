import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Box, Dialog, Stack, Typography, TextField, Grid, Button, Container} from "@mui/material";
import Logo from "../../general/logo";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as Yup from "yup";
import useOmniaApi from "../../../hooks/use-omnia-api";
import {useNotifications} from "../../../hooks/use-notifications";
import {setUserData} from "../../../store/actions/account-actions";
import authService from "../../../services/auth-service";
import {useLocation, useNavigate} from "react-router-dom";
import SaveButton from "../../elements/save-button";

function SecurityChecksBlocker({children}){

    const user = useSelector(state => state.account.user);
    const { t } = useTranslation();
    const { post } = useOmniaApi();
    const navigate = useNavigate();
    const [systemIsOnline, setSystemIsOnline] = useState(true);
    const [cookiesEnabled, setCookiesEnabled] = useState(true);
    const dispatch = useDispatch();
    const location = useLocation();

    const setTestCookie = () => {
        // Set a cookie "testcookie=1" that expires in 1 minute
        const now = new Date();
        const time = now.getTime();
        const expireTime = time + 1000*60;  // 1 minute from now
        now.setTime(expireTime);
        document.cookie = 'testcookie=1; expires=' + now.toUTCString() + '; path=/';
    }

    const checkCookieEnabled = () => {
        setTestCookie(); // Set the test cookie

        // Attempt to read the test cookie
        if (document.cookie.indexOf('testcookie=1') != -1) {
            // Clean up: remove the test cookie
            document.cookie = 'testcookie=1; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
            return true;
        } else {
            return false;
        }
    }

    const handleResetPassword = () => {
        navigate('/login');
    }

    const formik = useFormik({
        initialValues: {
            old_password: '',
            new_password: '',
            confirm_password: ''
        },
        validationSchema: Yup.object().shape({
            old_password: Yup.string().required(t('form.is_required', {field: t('core.old_password')})),
            new_password: Yup.string()
                .min(10, t('form.min_characters', {value: 10}))
                .required(t('form.is_required', {field: t('core.new_password')}))
                .test('str', t('must_have_a_number'), val => {
                    return /\d/.test(val);
                })
                .test('str', t('form.must_have_special_character'), val => {
                    return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(val);
                })
                .notOneOf([Yup.ref('old_password')], t('form.passwords_cannot_be_the_same')),
            confirm_password: Yup.string()
                .oneOf([Yup.ref('new_password'), null], t('core.passwords_dont_match'))
                .required(t('form.is_required', {field: t('core.confirm_password')}))
        }),
        onSubmit: (values, helpers) => {
            helpers.setSubmitting(true);
            post('core/me/change_password', values).then((data) => {

                // Update the token
                authService.setSession(data.token);

                // Update the user
                dispatch(setUserData(data['user']));

            }).catch(errors => {
                console.log(errors);
            }).finally(() => {
                helpers.setSubmitting(false);
            })
        }
    })

    const noCookies = !cookiesEnabled && location.pathname.includes('groon');

    const forcePasswordReset = user?.force_password_change && location.pathname.includes('groon');

    useEffect(() => {

        // Check if cookies are enabled
        setCookiesEnabled(checkCookieEnabled());

        // Add offline listener FIXME: this only seems to work AFTER it was connected once and not initially
        window.addEventListener('offline', () => {
            setSystemIsOnline(false);
        });

        // Add online listener
        window.addEventListener('online', () => {
            setSystemIsOnline(true);
        });

    }, []);

    if(noCookies){
        return (
            <Dialog open={true} fullScreen={true}>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={4}
                    sx={{height: '100vh', width: '100%'}}
                >
                    <Stack
                        direction="column"
                        spacing={2}
                        alignItems="center"
                    >
                        <Logo width={150} />
                        <Typography variant="h4" align="center">
                            {t('pages.cookies_not_enabled')}
                        </Typography>
                        <Typography variant="subtitle1" align="center">
                            {t('pages.cookies_not_enabled_info')}
                        </Typography>
                    </Stack>
                </Stack>
            </Dialog>
        )
    }

    if(forcePasswordReset){
        return (
            <Dialog open={true} fullScreen={true}>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={4}
                    sx={{height: '100vh', width: '100%'}}
                >
                    <Logo width={150} />
                    <Container maxWidth="sm">
                        <Typography variant="h4" align="center">
                            {user?.last_password_reset === null
                                ? t('pages.welcome_to_on')
                                : t('pages.you_need_to_change_pw')
                            }
                        </Typography>
                        <Typography variant="subtitle1" align="center">
                            {user?.last_password_reset === null
                                ? t('pages.choose_pw_to_start')
                                : t('pages.force_pw_reset_explain')
                            }
                        </Typography>
                        <Box mt={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={user?.last_password_reset === null ? t('core.initial_password') : t('core.old_password')}
                                        type="password"
                                        name="old_password"
                                        error={formik.touched.old_password && Boolean(formik.errors.old_password)}
                                        helperText={formik.touched.old_password && formik.errors.old_password}
                                        value={formik.values.old_password}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label={user?.last_password_reset === null ? t('core.your_password') : t('core.new_password')}
                                        type="password"
                                        name="new_password"
                                        error={formik.touched.new_password && Boolean(formik.errors.new_password)}
                                        helperText={formik.touched.new_password && formik.errors.new_password}
                                        value={formik.values.new_password}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label={t('core.confirm_password')}
                                        type="password"
                                        name="confirm_password"
                                        error={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
                                        helperText={formik.touched.confirm_password && formik.errors.confirm_password}
                                        value={formik.values.confirm_password}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mt={2}>
                            <Stack direction="row" justifyContent="space-between">
                                <SaveButton
                                    loading={formik.isSubmitting}
                                    onClick={formik.handleSubmit}
                                    noIcon
                                >
                                    {user?.last_password_reset === null ? t('common.get_started') : t('pages.update_password')}
                                </SaveButton>
                                {user?.last_password_reset !== null && (
                                    <Button
                                        variant="text"
                                        onClick={handleResetPassword}
                                    >
                                        {t('pages.forgot_password')}
                                    </Button>
                                )}
                            </Stack>
                        </Box>
                    </Container>
                </Stack>
            </Dialog>
        )
    }

    if(!systemIsOnline){
        return (
            <Dialog open={true} fullScreen={true}>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={4}
                    sx={{height: '100vh', width: '100%'}}
                >
                    <Container maxWidth="sm">
                        <Typography variant="h4" align="center">
                            {t('common.you_are_offline')}
                        </Typography>
                        <Typography variant="subtitle1" align="center">
                            {t('common.you_are_offline_info')}
                        </Typography>
                    </Container>
                </Stack>
            </Dialog>
        )
    }

    return children;
}

export default SecurityChecksBlocker;