import MessagesIcon from '@untitled-ui/icons-react/build/esm/MessageChatCircle';
import {Badge, IconButton, SvgIcon, Tooltip, useMediaQuery} from '@mui/material';
import {usePopover} from 'src/omnia/hooks/use-popover';
import {ChatPopover} from './chat-popover';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useSecurityCheck} from "../../../../hooks/use-security-check";
import {useIsMobile} from "../../../../hooks/use-is-mobile";

export const ChatButton = () => {

    const popover = usePopover();
    const { isMobile } = useIsMobile();
    const { hasRights, isModuleInstalled } = useSecurityCheck();
    const unread = useSelector(state => state.messages.unread);

    if(!hasRights(['social_intranet']) || !isModuleInstalled('intranet') || isMobile)
        return null;

    return (
        <>
            <IconButton
                onMouseEnter={() => {
                    popover.handleOpen();
                    popover.queueOpen();
                }}
                onMouseLeave={() => {
                    if(!isMobile){
                        popover.queueClose();
                    }
                }}
                ref={popover.anchorRef}
            >
                <Badge
                    color="error"
                    badgeContent={unread > 0 ? unread : 0}
                >
                    <SvgIcon>
                        <MessagesIcon/>
                    </SvgIcon>
                </Badge>
            </IconButton>
            <ChatPopover popover={popover} />
        </>
    );
};
