import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import {useTheme} from "@mui/system";

const TransitionOverlay = () => {
    const isTransitioning = useSelector(state => state.account.isTransitioning);

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'background.default',
                // backgroundColor: 'red',
                transition: 'opacity 0.5s ease-in-out',
                opacity: isTransitioning ? 1 : 0,
                zIndex: 9998,
                pointerEvents: isTransitioning ? 'auto' : 'none'
            }}
        />
    );
};

export default TransitionOverlay;
