import React from 'react';
import PropTypes from 'prop-types';
import {useSettings} from 'src/omnia/hooks/use-settings';
import {useSections} from './config';
import {VerticalLayout} from './vertical-layout';
import {FlyingLayout} from './flying-layout';

export const Layout = (props) => {

    const settings = useSettings();
    const sections = useSections();

    if (settings.layout === 'vertical') {
        return (
            <VerticalLayout
                sections={sections}
                navColor={settings.navColor}
                navMode={settings.navMode}
                {...props}
            />
        );
    }

    return (
        <FlyingLayout
            sections={sections}
            navColor={settings.navColor}
            navMode={settings.navMode}
            {...props}
        />
    )
};

Layout.propTypes = {
    children: PropTypes.node
};
