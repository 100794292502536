import React from 'react';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import {SvgIcon} from "@mui/material";
import {Star} from "react-feather";
import {useCreateSrc} from "src/omnia/hooks/use-create-src";
import PropTypes from "prop-types";
import {useTheme} from "@mui/system";

function UserAvatar({user, children, noStar, onLoad, ...rest}) {

    const getSrc = useCreateSrc();
    const theme = useTheme();

    const handleAvatarLoaded = () => {
        if(typeof(onLoad) !== "undefined")
            setTimeout(onLoad(), 500);
    }

    if(typeof (user) === "undefined" || !user)
        return null;

    // when there is no avatar call onload
    if(typeof(onLoad) !== "undefined" && user.avatar === null)
        onLoad();

    if(!user.is_favorite)
        return (
            <Avatar
                src={user.avatar?.view ? (user.avatar?.view_lowest) : null}
                to={'/groon/profile/' + user.id}
                onLoad={handleAvatarLoaded}
                {...rest}
            >
                {children}
            </Avatar>
        );

    return (
        <div style={{
            display: 'flex',
            '& > *': {
                margin: theme.spacing(0),
            },
        }}>
            <Badge
                overlap="circular"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                badgeContent={!noStar && (
                    <SvgIcon fontSize="small" style={{
                        ...(theme.palette.mode === 'light' ? {
                            boxShadow: 'none',
                            backgroundColor: theme.palette.primary.main
                        } : {}),
                        ...(theme.palette.mode === 'dark' ? {
                            backgroundColor: theme.palette.primary.light
                        } : {}),
                        borderRadius: "50%",
                        padding: 4,
                    }}>
                        <Star style={{color: "white"}} />
                    </SvgIcon>
                )}
            >
                <Avatar {...rest} src={getSrc(user.avatar, true)} to={'/groon/profile/' + user.id} onLoad={handleAvatarLoaded}  >
                    {children}
                </Avatar>
            </Badge>
        </div>
    );
}

UserAvatar.propTypes = {
    noStar: PropTypes.bool,
    user: PropTypes.object,
    children: PropTypes.string,
    onLoad: PropTypes.func
};

UserAvatar.defaultProps = {
    noStar: false
};

export default UserAvatar;