import React, { useEffect, useState, useCallback } from "react";
import {List, ListItem, Button, Stack, Typography, CircularProgress, Divider} from "@mui/material";
import useOmniaApi from "../../../hooks/use-omnia-api";
import {useTranslation} from "react-i18next";
import usePagination from "../../../hooks/use-pagination";
import OnIcon from "../icon";
import {useTheme} from "@mui/system";

function OverviewList({ endpoint, renderItem, query, height, pageSize, minHeight, maxHeight, topItem, ...rest }) {
    const [page, setPage] = useState(1);
    const theme = useTheme();

    const { elements, initialLoading, pages, isMore, setElements } = usePagination({
        endpoint: endpoint,
        page: page,
        append: true,
        query: query,
        pageSize: pageSize
    });

    const { t } = useTranslation();

    const handleLoadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    if(initialLoading)
        return (
            <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                    ...(height ? {height: height} : maxHeight ? {maxHeight: maxHeight} : {height: minHeight > 400 ? minHeight : 400}),
                    ...(minHeight ? {minHeight: minHeight} : {}),
                }}
                {...rest}
            >
                <CircularProgress size={20} />
            </Stack>
        )

    if(elements.length === 0)
        return (
            <Stack
                spacing={2}
                justifyContent="center"
                alignItems="center"
                sx={{
                    ...{width: '100%', textAlign: 'center'},
                    ...(height ? {height: height} : {maxHeight: maxHeight}),
                    ...(minHeight ? {minHeight: minHeight} : {}),
                }}
                {...rest}
            >
                <OnIcon
                    iconName="SearchRefraction"
                    size="large"
                    sx={{color: theme.palette.text.secondary}}
                />
                <Typography variant="h5" color="textSecondary">
                    {t("common.sst_no_results")}
                </Typography>
            </Stack>
        )

    return (
        <List
            sx={{
                ...{ overflow: 'auto' },
                ...(height ? {height: height} : {maxHeight: maxHeight}),
                ...(minHeight ? {minHeight: minHeight} : {}),
            }}
            {...rest}
        >
            {topItem && topItem}
            {elements.map((item, index) => renderItem(item, index))}
            {isMore && (
                <ListItem>
                    <Button
                        fullWidth
                        onClick={handleLoadMore}
                        disabled={!isMore}
                    >
                        {t("common.load_more")}
                    </Button>
                </ListItem>
            )}
        </List>
    );
}

OverviewList.defaultProps = {
    topItem: null,
    maxHeight: 450,
    minHeight: null,
    pageSize: 10
}

export default OverviewList;
