import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import ChevronDownIcon from '@untitled-ui/icons-react/build/esm/ChevronDown';
import ChevronRightIcon from '@untitled-ui/icons-react/build/esm/ChevronRight';
import {Box, ButtonBase, Chip, Collapse, SvgIcon, Typography} from '@mui/material';
import {RouterLink} from 'src/omnia/components/elements/router-link';
import {useTranslation} from "react-i18next";
import OnIcon from "../../../elements/icon";
import HistoryPopover from "../../../modules/intelligence/history-popover";
import usePopover from "../../../../hooks/use-popover";
import SearchDialog from "../../../elements/search-dialog";
import SearchButton from "../../components/search-button";

export const SideNavItem = (props) => {
    const {
        active,
        children,
        chip,
        depth = 0,
        disabled,
        external,
        icon,
        label,
        superCustomAction,
        open: openProp,
        path,
        title
    } = props;

    const [open, setOpen] = useState(!!openProp);
    const popover = usePopover();

    const { t } = useTranslation();

    const handleToggle = useCallback(() => {
        setOpen((prevOpen) => !prevOpen);
    }, []);

    const offset = depth === 0 ? 0 : (depth - 1) * 16;

    // Branch

    if (children) {
        return (
            <li>
                <ButtonBase
                    disabled={disabled}
                    onClick={handleToggle}
                    sx={{
                        alignItems: 'center',
                        borderRadius: 1,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        pl: `${(depth === 0 ? 10 : 42) + offset}px`,
                        pr: '14px',
                        py: (depth === 0) ? '5px' : '8px',
                        textAlign: 'left',
                        width: '100%',
                        ...(active && {
                            ...(depth === 0 && {
                                backgroundColor: 'var(--nav-item-active-bg)'
                            })
                        }),
                        '&:hover': {
                            backgroundColor: 'var(--nav-item-hover-bg)'
                        }
                    }}
                >
                    {(depth === 0) && (
                        <Box
                            component="span"
                            sx={{
                                alignItems: 'center',
                                color: 'var(--nav-item-icon-color)',
                                display: 'inline-flex',
                                justifyContent: 'center',
                                mr: 2,
                                ...(active && {
                                    color: 'var(--nav-item-icon-active-color)'
                                })
                            }}
                        >
                            <OnIcon iconName={icon} size="small" />
                        </Box>
                    )}
                    <Box
                        component="span"
                        sx={{
                            color: 'var(--nav-item-color)',
                            flexGrow: 1,
                            fontFamily: (theme) => theme.typography.fontFamily,
                            // fontSize: depth > 0 ? 11 : 12,
                            // fontWeight: depth > 0 ? 400 : 600,
                            // lineHeight: '18px',
                            whiteSpace: 'nowrap',
                            ...(active && {
                                color: 'var(--nav-item-active-color)'
                            }),
                            ...(disabled && {
                                color: 'var(--nav-item-disabled-color)'
                            })
                        }}
                    >
                        <Typography
                            variant={depth > 0 ? "body2" : "body1"}
                            sx={{fontWeight: depth > 0 ? 400 : 600, lineHeight: 1}}
                        >
                            {t(title)}
                        </Typography>
                    </Box>
                    <SvgIcon
                        sx={{
                            color: 'var(--nav-item-chevron-color)',
                            fontSize: 14,
                            ml: 2
                        }}
                    >
                        {open ? <ChevronDownIcon/> : <ChevronRightIcon/>}
                    </SvgIcon>
                </ButtonBase>
                <Collapse
                    in={open}
                    sx={{mt: 0.5}}
                >
                    {children}
                </Collapse>
            </li>
        );
    }

    // Leaf

    const linkProps = path
        ? external
            ? {
                component: 'a',
                href: path,
                target: '_blank'
            }
            : {
                component: RouterLink,
                href: path
            }
        : {};

    return (
        <li
            ref={popover.anchorRef}
            onMouseEnter={() => {
                if(superCustomAction !== 'search') {
                    popover.handleOpen();
                    popover.queueOpen();
                }
            }}
            onMouseLeave={() => {
                if(superCustomAction !== 'search') {
                    popover.queueClose();
                }
            }}
            onClick={() => {
                if(popover.open) {
                    popover.handleClose();
                } else {
                    popover.handleOpen();
                }
            }}
        >
            {superCustomAction === 'extend-intelligence-chats' && (
                <HistoryPopover popover={popover} />
            )}
            {superCustomAction === 'search' ? (
                <>
                    <SearchDialog popover={popover} />
                    <SearchButton
                        depth={depth}
                        offset={offset}
                    />
                </>
            ) : (
                <ButtonBase
                    disabled={disabled}
                    sx={{
                        alignItems: 'center',
                        borderRadius: 1,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        pl: `${(depth === 0 ? 10 : 42) + offset}px`,
                        pr: '14px',
                        py: (depth === 0) ? '5px' : '8px',
                        textAlign: 'left',
                        width: '100%',
                        ...(active && {
                            ...(depth === 0 && {
                                backgroundColor: 'var(--nav-item-active-bg)'
                            })
                        }),
                        '&:hover': {
                            backgroundColor: 'var(--nav-item-hover-bg)'
                        }
                    }}
                    {...linkProps}
                >
                    {(depth === 0) && (
                        <Box
                            component="span"
                            sx={{
                                alignItems: 'center',
                                color: 'var(--nav-item-icon-color)',
                                display: 'inline-flex',
                                justifyContent: 'center',
                                mr: 2,
                                ...(active && {
                                    color: 'var(--nav-item-icon-active-color)'
                                })
                            }}
                        >
                            <OnIcon iconName={icon} size="small" />
                        </Box>
                    )}
                    <Box
                        component="span"
                        sx={{
                            color: 'var(--nav-item-color)',
                            flexGrow: 1,
                            fontFamily: (theme) => theme.typography.fontFamily,
                            // fontSize: depth > 0 ? 11 : 12,
                            fontWeight: depth > 0 ? 400 : 600,
                            // lineHeight: '18px',
                            whiteSpace: 'nowrap',
                            ...(active && {
                                color: 'var(--nav-item-active-color)'
                            }),
                            ...(disabled && {
                                color: 'var(--nav-item-disabled-color)'
                            })
                        }}
                    >
                        <Typography
                            variant={depth > 0 ? "body2" : "body1"}
                            sx={{fontWeight: depth > 0 ? 400 : 600, lineHeight: 1}}
                        >
                            {t(title)}
                        </Typography>
                    </Box>
                    {label && (
                        <Box
                            component="span"
                            sx={{ml: 2}}
                        >
                            {label}
                        </Box>
                    )}
                    {chip && (
                        <Chip
                            label={chip}
                            variant="filled"
                            size="small"
                            sx={{height: 20, fontSize: 9}}
                        />
                    )}
                </ButtonBase>
            )}

        </li>
    );
};

SideNavItem.defaultProps = {
    chip: null
}

SideNavItem.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.node,
    depth: PropTypes.number,
    chip: PropTypes.any,
    disabled: PropTypes.bool,
    external: PropTypes.bool,
    icon: PropTypes.node,
    open: PropTypes.bool,
    path: PropTypes.string,
    title: PropTypes.string.isRequired
};
