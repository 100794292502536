import {createTypography} from './create-typography';
import {createComponents} from './create-components';

// Here we do not modify the "palette" and "shadows" because "light" and "dark" mode
// may have different values.

export const createOptions = (config) => {
    const {direction = 'ltr', border_radius = 8} = config;

    return {

        // Breakpoints where what device size is considered what
        breakpoints: {
            values: {
                xs: 0,
                sm: 525,
                md: 750,
                lg: 900,
                xl: 1175
            }
        },

        // Spacing for the system
        spacing: 6,

        // Store the configuration of this theme
        config: config,

        // Create the basic components
        components: createComponents(config),

        // Store the orientation of the theme
        direction: direction,

        // Set the global shapes
        shape: {
            borderRadius: parseInt(border_radius)
        },

        // Create the typography for the theme
        typography: createTypography(config)
    };
};
