import PropTypes from 'prop-types';
import { Box, Container } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import ErrorHandler from "../../elements/error-handler";
import { useTheme } from "@mui/system";
import { useAppMode } from "../../../hooks/use-app-mode";
import { useState } from 'react';

const LayoutRoot = styled('div')(({ theme, imageLoaded }) => ({
    position: 'relative',
    backgroundColor: theme.palette.background.default,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundSize: 'cover',
    backgroundImage: `url("${theme?.config?.auth_background?.view || '/assets/images/gradient-bg.svg'}")`,
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    height: '100%',
    opacity: imageLoaded ? 1 : 0, // Use state to control opacity
    transition: 'opacity 1s ease-in-out', // Smooth transition for the opacity
    "&::before": {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: alpha(theme.palette.background.paper, (theme.palette.mode === 'light' ? 0.5 : 0.8)),
        zIndex: 1,
    },
    "& > *": {
        position: 'relative',
        zIndex: 2,
    },
}));

export const Layout = (props) => {
    const { children } = props;
    const { isService } = useAppMode();
    const theme = useTheme();
    const [imageLoaded, setImageLoaded] = useState(false);

    return (
        <LayoutRoot imageLoaded={imageLoaded}>
            <ErrorHandler>
                <img
                    src={theme?.config?.auth_background?.view || '/assets/images/gradient-bg.svg'}
                    alt=""
                    style={{ display: 'none' }} // Hide this img element
                    onLoad={() => setImageLoaded(true)} // Set the state to true when image loads
                />
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        flex: '1 1 auto'
                    }}
                >
                    <Container
                        maxWidth="sm"
                        sx={{
                            py: {
                                xs: '60px',
                                md: '120px'
                            }
                        }}
                    >
                        {children}
                    </Container>
                </Box>
            </ErrorHandler>
        </LayoutRoot>
    );
};

Layout.propTypes = {
    children: PropTypes.node
};
