import React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

function SearchDialog({popover}){
    const { t } = useTranslation();

    return (
        <Dialog
            open={popover.open}
            onClose={popover.handleClose}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle>
                {/*<Typography variant="h4">*/}
                {/*    {t('common.search')}*/}
                {/*</Typography>*/}
            </DialogTitle>
            <DialogContent>
                <div style={{height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Typography variant="caption" color="textPrimary">{t("common.coming_soon")}</Typography>
                </div>
            </DialogContent>
            <DialogActions>

            </DialogActions>
        </Dialog>
    )
}

SearchDialog.propTypes = {
    popover: PropTypes.object
};

export default SearchDialog;