export const imageTypes = ['png', 'jpg', 'JPG', 'jpeg', 'tiff', 'heic', 'heif', 'gif', 'bmp', 'svg', 'webp'];
export const videoTypes = ['mp4', 'mov'];
export const docTypes = ['pdf'];

export function isDisplayableType(type){
    if(!type)
        return false;
    return docTypes.includes(type.toLowerCase()) || imageTypes.includes(type.toLowerCase()) || videoTypes.includes(type.toLowerCase());
}

export function isMediaType(type){
    if(!type)
        return false;
    return imageTypes.includes(type.toLowerCase()) || videoTypes.includes(type.toLowerCase());
}