import React, {useEffect, useState} from 'react';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
    transition: 'transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
    boxShadow: theme.shadows[2],
    '&:hover': {
        // transform: 'scale(1.01)',
        cursor: 'pointer',
        boxShadow: theme.shadows[6],
    },
}));

function HoverableCard({onHoverChange, children, ...rest}) {

    const [ hovered, setHovered ] = useState(false);

    useEffect(() => {
        onHoverChange?.(hovered);
    }, [hovered]);

    return (
        <StyledCard
            onMouseLeave={() => setHovered(false)}
            onMouseEnter={() => setHovered(true)}
            {...rest}
        >
            {children}
        </StyledCard>
    );
}

export default HoverableCard;
