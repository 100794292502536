import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {setUserData} from 'src/omnia/store/actions/account-actions';
import authService from 'src/omnia/services/auth-service';
import useApi from "../../../hooks/use-api";
import {setLoadingUser, setUser} from "../../../store/actions/service-actions";
import {useAppMode} from "../../../hooks/use-app-mode";
import LoadingScreen from "../loading-screen";

const Auth = ({children}) => {

    const dispatch = useDispatch();
    const [isLoadingWebsite, setLoadingWebsite] = useState(true);
    const [isLoadingBackend, setLoadingBackend] = useState(true);
    const [isLoadingService, setLoadingService] = useState(true);
    const serviceUser = useSelector(state => state.service.user);
    const backendUser = useSelector(state => state.account.user);
    const appMode = useAppMode();
    const { put, get } = useApi(false);

    useEffect(() => {
        if(appMode.isBackend && !backendUser){
            const token = authService.getAccessToken();
            if(!token){
                setLoadingBackend(false);
            } else {
                put('/core/me/login', {'token': token}).then((data) => {
                    authService.setAccessToken(data.token);
                    dispatch(setUserData(data.user));
                }).catch(() => {
                    authService.logout();
                }).finally(() => {
                    setLoadingBackend(false);
                });
            }
        } else {
            setLoadingBackend(false);
        }
    }, [appMode.isBackend, backendUser]);

    useEffect(() => {
        if(appMode.isService && !serviceUser){
            dispatch(setLoadingUser(true));
            const serviceToken = localStorage.getItem('serviceToken');
            if(!serviceToken){
                dispatch(setLoadingUser(false));
                setLoadingService(false);
            } else {
                put('/services/login', {'token': serviceToken}).then((data) => {
                    dispatch(setUser(data?.user));
                }).catch(() => {
                    localStorage.removeItem('serviceToken');
                    dispatch(setUser(null));
                }).finally(() => {
                    setLoadingService(false);
                    dispatch(setLoadingUser(false));
                });
            }
        } else {
            setLoadingService(false);
        }
    }, [appMode.isService, serviceUser]);

    useEffect(() => {
        if(appMode.isWebsite){

            // TODO: Maybe perform some auth later?

            setLoadingWebsite(false);
        } else {
            setLoadingWebsite(false);
        }
    }, [appMode.isWebsite]);

    useEffect(() => {
        if(appMode.isBackend && !appMode.backendTheme){
            appMode.addLoadingElement('design');
            get('setup/design').then((data) => {

                appMode.setBackendTheme({
                    ...data.theme,
                    ...{
                        noAdmin: data?.no_admin || false,
                        aiOn: data?.ai_on || false,
                    }
                });

            }).finally(() => {
                appMode.removeLoadingElement('design');
            })
        }

    }, [appMode.isBackend, appMode.backendTheme]);

    if(isLoadingWebsite || isLoadingBackend || isLoadingService)
        return <LoadingScreen inScreen={false} />

    return children;

};

Auth.propTypes = {
    children: PropTypes.any
};

export default Auth;
