import {useTheme} from "@mui/system";
import {useIsMobile} from "src/omnia/hooks/use-is-mobile";
import React, {useEffect, useState} from "react";
import {Avatar, Box, Card, CardMedia, Fade, Link, Stack, Tooltip, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import Linkify from "react-linkify";
import PerfectScrollbar from "react-perfect-scrollbar";
import PostFileItem from "src/omnia/components/modules/core/connect/post-card/post-file-item";
import moment from "moment/moment";
import {Lightbox} from "react-modal-image";
import { imageTypes, videoTypes, docTypes } from 'src/omnia/utils/file-type-helpers';
import PropTypes from "prop-types";
import HtmlWrapper from "src/omnia/components/elements/html-wrapper";
import {APP_SETTING} from "../../../../../../setup";
import {useDialog} from "../../../../../hooks/use-dialog";
import ActionDetailsDialog from "../../../intelligence/assistants/action-details-dialog";
import {useTranslation} from "react-i18next";
import OnIcon from "../../../../elements/icon";
import HoverableCard from "../../../../elements/hoverable-card";
import {useSelector} from "react-redux";

export const MessageRenderer = (props) => {

    const {
        message,
        assistant,

        contentType,
        position,
        body,
        isEmoji,
        files,
        onFileUpdate,
        smallVersion,

        lastUserMessage,
        firstUserMessage,
        isShortMessage,
        ...other
    } = props;

    const theme = useTheme();
    const { isMobile } = useIsMobile();
    const { t } = useTranslation();
    const user = useSelector(state => state.user);
    const [openedFile, setOpenedFile] = useState(null);
    const [hovered, setHovered] = useState(false);
    const actionDialog = useDialog();

    const longAgo = moment().diff(moment(message.sent_at), 'days') > 1;

    const senderLogo = message?.role === 'user' ? (user?.avatar || null) : (message?.sender_logo || assistant?.logo || null);

    const senderIcon = message?.role === 'user' ? (user?.avatar || null) : (message?.sender_icon || assistant?.icon || null);

    const isChatBubble = (smallVersion || contentType === 'image') || isShortMessage;

    const openActionDetails = (actions) => {
        actionDialog.handleOpen(actions);
    }

    const chatBubble = (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: position === 'right' ? 'flex-end' : 'flex-start',
                    maxWidth: '100%'
                }}
                id="ChatBubble"
                {...other}
            >
                <Stack
                    alignItems="flex-start"
                    direction={position === 'right' ? 'row-reverse' : 'row'}
                    spacing={2}
                    sx={{
                        // maxWidth: 500,
                        maxWidth: 'min(100%, 500px)',
                        ml: position === 'right' ? 'auto' : 0,
                        mr: position === 'left' ? 'auto' : 0
                    }}
                >

                    <Box sx={{display: 'flex', flexGrow: 1, flexDirection: 'column', width: '100%'}}>
                        {isEmoji ? (
                            <>
                                <Box>
                                    {(firstUserMessage && (position === "left")) && (
                                        <Box sx={{
                                            px: 1.75,
                                            py: smallVersion ? 0 : 0.75,
                                            pt: 0.75,
                                        }}>
                                            <Link
                                                underline={message?.assistant_id ? "hover" : "none"}
                                                color="inherit"
                                                component={RouterLink}
                                                to={'/groon/ai/assistants/' + message?.assistant_id}
                                                sx={{
                                                    cursor: 'pointer',
                                                    fontWeight: 600,
                                                    fontSize: smallVersion ? 12 : 13,
                                                }}
                                            >
                                                {message?.sender_name || assistant?.name}
                                            </Link>
                                        </Box>
                                    )}
                                </Box>
                                <Box
                                    style={{
                                        fontSize: smallVersion ? 40 : 50,
                                        textAlign: position
                                    }}
                                >
                                    {body}
                                </Box>
                            </>
                        ) : (
                            <HoverableCard
                                onHoverChange={setHovered}
                                sx={{
                                    backgroundColor: position === 'right' ? 'primary.main' : 'background.paper',
                                    color: position === 'right' ? 'primary.contrastText' : 'text.primary',
                                    borderRadius: theme?.config?.message_radius + 'px',
                                    maxWidth: '100%'
                                }}
                            >
                                {contentType === 'image' && (
                                    <CardMedia
                                        component="img"
                                        onClick={() => setOpenedFile(body)}
                                        image={body}
                                        height="auto"
                                        maxWidth="100%"
                                        sx={{ maxWidth: '100%' }}
                                    />
                                )}
                                {contentType === 'text' && (
                                    <>
                                        <Typography
                                            sx={{
                                                px: 1.5,
                                                py: 0.75
                                            }}
                                            color="inherit"
                                            variant={(smallVersion || isMobile) ? "body2" : "body1"}
                                        >
                                            <Linkify properties={{target: '_blank', style: {color: position === "right" ? theme.palette.primary.contrastText : theme.palette.primary.main}}}>
                                                <HtmlWrapper
                                                    html={body}
                                                    styles={{
                                                        fontSize: (smallVersion || isMobile) ? theme.config?.font_body2_size : theme.config?.font_subtitle1_size
                                                    }}
                                                />
                                            </Linkify>
                                        </Typography>
                                        {files && (files.length > 0) && (
                                            <Box mt={2}>
                                                <PerfectScrollbar>
                                                    <Box style={{maxHeight: 500}}>
                                                        {files.map((file, i) => {
                                                            return (
                                                                <PostFileItem
                                                                    key={message.id + '-file-' + i}
                                                                    simpleIcon={true}
                                                                    file={file}
                                                                    showDivider={i < files.length - 1}
                                                                    deleteCallback={onFileUpdate}
                                                                    onUpdate={onFileUpdate}
                                                                    sx={{color: (position === "right" ? theme.palette.primary.contrastText : theme.palette.text.primary)}}
                                                                />
                                                            )
                                                        })}
                                                    </Box>
                                                </PerfectScrollbar>
                                            </Box>
                                        )}
                                    </>
                                )}

                            </HoverableCard>
                        )}
                    </Box>
                </Stack>
            </Box>
            {(lastUserMessage && longAgo) && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: position === 'right' ? 'flex-end' : 'flex-start',
                    }}
                    pl={position === 'right' ? 0 : 1}
                    pr={position === 'left' ? 0 : 1}
                >
                    <Typography
                        color="text.secondary"
                        noWrap
                        variant="caption"
                    >
                        {moment(message.sent_at).fromNow()}
                    </Typography>
                </Box>
            )}

        </>
    )

    const largeMessage = (
        <Stack direction="column" spacing={1} sx={{maxWidth: '100%'}} id="LargeMessage">
            <Typography
                color="inherit"
                variant="subtitle1"
                // sx={{lineHeight: 1.5}}
            >
                <Linkify properties={{target: '_blank', style: {color: isChatBubble ? theme.palette.primary.contrastText : theme.palette.primary.main}}}>
                    <HtmlWrapper html={body} />
                </Linkify>
            </Typography>
            {files && (files.length > 0) && (
                <Box>
                    <PerfectScrollbar>
                        <Box style={{maxHeight: 500}}>
                            {files.map((file, i) => {
                                return (
                                    <PostFileItem
                                        key={message.id + '-file-' + i}
                                        simpleIcon={true}
                                        file={file}
                                        showDivider={i < files.length - 1}
                                        deleteCallback={onFileUpdate}
                                        onUpdate={onFileUpdate}
                                        sx={{color: (position === "right" ? theme.palette.primary.contrastText : theme.palette.text.primary)}}
                                    />
                                )
                            })}
                        </Box>
                    </PerfectScrollbar>
                </Box>
            )}
            {(lastUserMessage && longAgo) && (
                <Box
                    pl={0}
                    pr={0}
                >
                    <Typography
                        color="text.secondary"
                        noWrap
                        variant="caption"
                    >
                        {moment(message.sent_at).fromNow()}
                    </Typography>
                </Box>
            )}
        </Stack>
    )

    if(!body)
        return null;

    return (
        <Stack
            id="MessageRendererComponent"
            direction={(message?.role === 'user' && isChatBubble) ? "row-reverse" : "row"}
            sx={{
                width: '100%',
                ml: message?.role === 'user' ? (isChatBubble ? 0 : '-35px') : senderLogo ? '-33px' : senderIcon ? '-38px' : '0px',
                mt: firstUserMessage ? (smallVersion ? 4 : 8) : (smallVersion ? 0.1 : 1),
            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            spacing={2}
        >
            {message?.role !== 'system' && (
                <>
                    {(message?.role === 'user') ? (
                        <>
                            {!isChatBubble && (
                                <Avatar
                                    src={senderLogo}
                                    sx={{
                                        width: 22,
                                        height: 22,
                                    }}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            {senderLogo && (
                                <Tooltip title={message?.sender_name || null} placement='left'>
                                    <Box
                                        component="img"
                                        src={senderLogo + (theme.palette.mode === 'dark' ? '-light.png' : '-dark.png')}
                                        alt="Assistant Logo"
                                        sx={{
                                            width: 20,
                                            height: 20,
                                        }}
                                    />
                                </Tooltip>
                            )}
                            {senderIcon && (
                                <Tooltip title={message?.sender_name || null} placement='left'>
                                    <OnIcon iconName={senderIcon} size="small" />
                                </Tooltip>
                            )}
                        </>
                    )}
                </>
            )}
            <Box sx={{width: '100%'}}>
                {message?.role === 'system' ? (
                    <Box sx={{maxWidth: 500, marginLeft: '40px'}}>
                        {message?.procedures?.length > 0 ? (
                            <Link
                                color="textSecondary"
                                variant="overline"
                                href='#'
                                underline='hover'
                                onClick={() => openActionDetails(message?.procedures)}
                            >
                                {body}
                            </Link>
                        ) : (
                            <Typography color="textSecondary" variant="overline">
                                {body}
                            </Typography>
                        )}
                    </Box>
                ) : isChatBubble ? chatBubble : largeMessage}
                {openedFile && (
                    <div style={{zIndex: 1600}}>
                        <Lightbox
                            large={openedFile}
                            medium={openedFile}  // FIXME: we should use .view_medium here
                            onClose={() => setOpenedFile(null)}
                        />
                    </div>
                )}
                <ActionDetailsDialog
                    open={actionDialog.open}
                    onClose={actionDialog.handleClose}
                    actions={actionDialog.data}
                />
            </Box>
        </Stack>
    )

};

const Message = ({message, smallVersion, index, initial, assistant, lastUserMessage, firstUserMessage, omitAnimation}) => {

    const [show, setShow] = useState(false);
    const [files, setFiles] = useState([]);
    const [featureImages, setFeatureImages] = useState([]);
    const [isEmoji, setIsEmoji] = useState(false);

    const updateFiles = (files) => {
        setFeatureImages([]);
        setFiles([]);
        for (let i = 0; i < files.length; i++) {
            if(imageTypes.includes(files[i].type.toLowerCase())){
                const feature = files[i].view;
                setFeatureImages(prev => prev.concat([feature]));
            } else {
                setFiles(prev => prev.concat([files[i]]));
            }
        }
    }

    useEffect(() => {
        if(initial)
            setShow(false);
        setTimeout(() => {
            setShow(true);
        }, initial ? (index * 30) : 0);
    }, [index, initial]);

    useEffect(() => {
        if (message.files.length > 0) {
            updateFiles(message.files);
        } else {
            setFiles([]);
            setFeatureImages([]);
        }
        // check if message is emoji
        setIsEmoji(/\p{Extended_Pictographic}/u.test(message.message) && !/[a-zA-Z]/.test(message.message));
    }, [message]);

    const content = (
        <Box sx={{width: '100%'}} id="MessageComponent">
            {featureImages.map((feature, index) => (
                <MessageRenderer
                    message={message}
                    key={message.id + '-' + index}

                    assistant={assistant}
                    contentType='image'
                    position={message.sender ? 'right' : 'left'}
                    isEmoji={false}
                    body={feature}
                    lastUserMessage={false}
                    firstUserMessage={false}

                    isShortMessage={false}
                    smallVersion={smallVersion}
                />
            ))}
            <MessageRenderer
                message={message}
                key={message.id}

                assistant={assistant}
                contentType='text'
                files={files}
                onFileUpdate={updateFiles}
                position={message.sender === null ? 'left' : 'right'}
                isEmoji={isEmoji}
                body={message.message}

                lastUserMessage={lastUserMessage}
                firstUserMessage={firstUserMessage}
                isShortMessage={message?.message?.length < 400}

                smallVersion={smallVersion}
            />
        </Box>
    )

    if(omitAnimation && !initial)
        return content;

    return (
        <Fade in={show} timeout={APP_SETTING?.transitionDuration || 500}>
            {content}
        </Fade>
    )
};

Message.propTypes = {
    message: PropTypes.object.isRequired,
    smallVersion: PropTypes.bool.isRequired,
    assistant: PropTypes.string,
    lastUserMessage: PropTypes.bool,
    firstUserMessage: PropTypes.bool,
    omitAnimation: PropTypes.bool
}

MessageRenderer.propTypes = {
    message: PropTypes.object.isRequired,
    contentType: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    isEmoji: PropTypes.bool.isRequired,
    files: PropTypes.array,
    onFileUpdate: PropTypes.func,
    smallVersion: PropTypes.bool,
    lastUserMessage: PropTypes.bool,
    firstUserMessage: PropTypes.bool
}

export default Message;