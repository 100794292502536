import React, {useState} from "react";
import PropTypes from 'prop-types';
import {
    ListItemButton,
    ListItemText,
    SvgIcon,
    Tooltip,
    List,
    Typography,
    ButtonBase,
    Avatar,
    Box,
    ListItemAvatar, Stack, Divider
} from '@mui/material';
import OnIcon from "../../../elements/icon";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router";
import {usePopover} from "../../../../hooks/use-popover";
import {RouterLink} from "../../../elements/router-link";
import HistoryPopover from "../../../modules/intelligence/history-popover";
import {useIsMobile} from "../../../../hooks/use-is-mobile";
import SmartPopper from "../../../elements/smart-popper";
import {useTheme} from "@mui/system";
import {TenantSwitch} from "../tenant-switch";
import CreateUserRequest from "../../../modules/core/create-user-request";
import SearchDialog from "../../../elements/search-dialog";
import {useSections} from "../config";
import {useSecurityCheck} from "../../../../hooks/use-security-check";

const FlyingNavSectionPopoverElement = ({item, onClose, posPercentage, ...rest}) => {
    const { isMobile } = useIsMobile();
    const theme = useTheme();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const popover = usePopover();

    const hasItems = item?.items?.length > 0;

    const handleTransition = (path) => {
        navigate(path);
        popover.handleClose();
        onClose?.();
    };

    if(item?.superCustomAction === 'search')
        return;

    return (
        <>
            <ListItemButton
                selected={item?.path === location?.pathname}
                component={RouterLink}
                href={item?.path}
                ref={popover.anchorRef}
                onClick={() => {
                    if (hasItems || (['superCustomAction', 'extend-intelligence-chats'].includes(item?.superCustomAction))) {
                        popover.handleOpen();
                    } else {
                        handleTransition(item?.path);
                    }
                }}
                onMouseEnter={() => {
                    if (hasItems || (['superCustomAction', 'extend-intelligence-chats'].includes(item?.superCustomAction))) {
                        popover.handleOpen();
                        popover.queueOpen();
                    }
                }}
                onMouseLeave={() => {
                    if (!isMobile && (hasItems || (['superCustomAction', 'extend-intelligence-chats'].includes(item?.superCustomAction)))) {
                        popover.queueClose();
                    }
                }}
                sx={{
                    borderRadius: 1,
                    px: 1,
                    py: 0.5,
                }}
                {...rest}
            >
                {item?.icon && (
                    <ListItemAvatar>
                        <OnIcon iconName={item?.icon} size="small" sx={{color: theme.palette.text.secondary}} />
                    </ListItemAvatar>
                )}
                <ListItemText
                    primary={(
                        <Typography variant="body1">
                            {t(item?.title)}
                        </Typography>
                    )}
                />
            </ListItemButton>
            {item?.superCustomAction === 'extend-intelligence-chats' ? (
                <HistoryPopover
                    popover={popover}
                    posPercentage={posPercentage}
                />
            ) : item?.superCustomAction === 'search' ? (
                <SearchDialog popover={popover} />
            ) : hasItems ? (
                <FlyingNavSectionPopover
                    popover={popover}
                    posPercentage={posPercentage}
                    items={item?.items}
                />
            ) : null}
        </>
    )
}

const FlyingNavSectionPopover = (props) => {
    const {popover, items, posPercentage, title, isHome, ...other} = props;
    const { isFlying } = useIsMobile();
    const { hasRights } = useSecurityCheck();

    return (
        <SmartPopper
            popover={popover}
            placement={isFlying ? (posPercentage > 0.5 ? 'right-end' : 'right-start') : (posPercentage > 0.5 ? 'bottom-start' : 'bottom-end')}
            minWidth={150}
            {...other}
        >
            {isHome ? (
                <>
                    <Stack direction="row" sx={{width: '100%'}} justifyContent="center">
                        <Box p={1} sx={{maxWidth: 180}}>
                            <TenantSwitch />
                        </Box>
                    </Stack>
                    <Divider />
                </>
            ) : title && (
                <>
                    <Stack direction="row" p={2}>
                        <Typography variant="h6">
                            {title}
                        </Typography>
                    </Stack>
                    <Divider />
                </>
            )}
            <List sx={{p: 1}}>
                {items?.map(item => (
                    <FlyingNavSectionPopoverElement
                        key={item?.title}
                        onClose={popover.handleClose}
                        posPercentage={posPercentage}
                        item={item}
                    />
                ))}
            </List>
            {(isHome && hasRights("groon_tickets")) && (
                <>
                    <Divider />
                    <CreateUserRequest light={true} />
                </>
            )}
        </SmartPopper>
    )

}

export const FlyingNavSection = ({section, allSectionPaths, posPercentage}) => {
    const {t} = useTranslation();
    const location = useLocation();
    const { isFlying, isMobile } = useIsMobile();
    const popover = usePopover();
    const [blockGoingBack, setBlockGoingBack] = useState(false);
    const isHome = section?.path === '/groon';
    const hasItems = section?.items?.length > 0;
    const modulePathIsIncluded = allSectionPaths?.some(path => location.pathname.includes(path));
    const isSelected = isHome ? !modulePathIsIncluded : location.pathname?.includes(section?.path);
    const navigate = useNavigate();

    const handleTransition = (path) => {
        navigate(path);
        setBlockGoingBack(true);
        setTimeout(() => {
            popover.handleClose();
        }, 100);
    };

    return (
        <>
            <Tooltip title={((hasItems || (section?.superCustomAction === 'extend-intelligence-chats')) && !isMobile) ? "" : t(section?.title)} placement={isFlying ? 'right' : 'bottom'}>
                <Box
                    component={ButtonBase}
                    onClick={() => {
                        if(isMobile){
                            popover.handleOpen();
                        } else {
                            if(section?.items?.filter(s => s.path)?.length > 0) {
                                handleTransition(section?.items?.filter(s => s.path)?.[0]?.path);
                            } else {
                                handleTransition(section?.path);
                            }
                            popover.handleClose();
                        }
                    }}
                    onMouseEnter={() => {
                        if(!blockGoingBack) {
                            popover.handleOpen();
                            popover.queueOpen();
                        }
                    }}
                    onMouseLeave={() => {
                        if(!isMobile){
                            popover.queueClose();
                        }
                        setBlockGoingBack(false);
                    }}
                    ref={popover.anchorRef}
                    sx={{
                        ...{
                            alignItems: 'center',
                            display: 'flex',
                            height: 40,
                            width: 40,
                            borderRadius: '50%',
                            color: 'text.secondary'
                        },
                        ...(isSelected ? {
                            borderWidth: 2,
                            borderStyle: 'solid',
                            borderColor: 'divider',
                            color: 'text.primary',
                        } : {})
                    }}
                >
                    {isSelected ? (
                        <Avatar sx={{height: 32, width: 32}}>
                            <OnIcon iconName={section?.icon}/>
                        </Avatar>
                    ) : (
                        <SvgIcon>
                            <OnIcon iconName={section?.icon}/>
                        </SvgIcon>
                    )}
                </Box>
            </Tooltip>
            {section?.superCustomAction === 'extend-intelligence-chats' ? (
                <HistoryPopover
                    popover={popover}
                    posPercentage={posPercentage}
                />
            ) : (
                <FlyingNavSectionPopover
                    popover={popover}
                    posPercentage={posPercentage}
                    items={section?.items}
                    title={section.title}
                    isHome={isHome}
                />
            )}
        </>
    )

};

FlyingNavSectionPopoverElement.propTypes = {
    item: PropTypes.object,
    posPercentage: PropTypes.number,
    onClose: PropTypes.func,
}

FlyingNavSectionPopover.propTypes = {
    popover: PropTypes.object,
    items: PropTypes.array,
    posPercentage: PropTypes.number,
    title: PropTypes.string,
    isHome: PropTypes.bool,
};

FlyingNavSection.propTypes = {
    section: PropTypes.object,
    posPercentage: PropTypes.number,
    allSectionPaths: PropTypes.array,
};
