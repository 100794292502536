import ContactsIcon from '@untitled-ui/icons-react/build/esm/Users01';
import {IconButton, SvgIcon, Tooltip, useMediaQuery} from '@mui/material';
import {usePopover} from 'src/omnia/hooks/use-popover';
import {ContactsPopover} from './contacts-popover';
import {useTranslation} from "react-i18next";
import {useSecurityCheck} from "../../../../hooks/use-security-check";
import {useIsMobile} from "../../../../hooks/use-is-mobile";
import {useCallback} from "react";
import debounce from "lodash.debounce";

export const ContactsButton = () => {

    const popover = usePopover();
    const { isMobile } = useIsMobile();
    const { hasRights } = useSecurityCheck();

    if(!hasRights('social_intranet'))
        return;

    return (
        <>
            <IconButton
                onClick={popover.handleOpen}
                ref={popover.anchorRef}
                onMouseEnter={() => {
                    popover.handleOpen();
                    popover.queueOpen();
                }}
                onMouseLeave={() => {
                    if(!isMobile){
                        popover.queueClose();
                    }
                }}
            >
                <SvgIcon>
                    <ContactsIcon/>
                </SvgIcon>
            </IconButton>
            <ContactsPopover popover={popover} />
        </>
    );
};
