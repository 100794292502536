import React, { useState, useEffect } from 'react';
import {CircularProgress, Fade, Typography} from '@mui/material';
import { useTheme } from "@mui/system";
import PropTypes from "prop-types";

function LoadingScreen({inScreen}) {
    const theme = useTheme();
    const backgroundColor = theme?.palette?.background?.default || 'none';
    const [showLoadingText, setShowLoadingText] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowLoadingText(true);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);

    let colorMode = 'light';
    try {
        colorMode = JSON.parse(localStorage.getItem('app.settings') || JSON.stringify({paletteMode: 'light'}))?.paletteMode
    } catch (e) {
        console.log(e);
    }

    if(inScreen){
        return (
            <div style={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <div
            style={{
                alignItems: 'center',
                backgroundColor: backgroundColor === 'none' ? (colorMode === 'dark' ? '#050505' : '#ffffff') : backgroundColor,
                display: 'flex',
                flexDirection: 'column',
                position: 'fixed',
                top: 0,
                zIndex: 9999,
                left: 0,
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                padding: 0,
                margin: 0,
            }}
        >
            <Fade in={showLoadingText}>
                {/* This seems to not work */}
                {theme?.config ? (
                    <CircularProgress />
                ) : (
                    <Typography
                        variant="overline"
                        sx={{color: colorMode === 'light' ? '#050505' : '#ffffff'}}
                        color="textPrimary"
                    >
                        Laden...
                    </Typography>
                )}
            </Fade>
        </div>
    );
}

LoadingScreen.propTypes = {
    inScreen: PropTypes.bool
};

LoadingScreen.defaultProps = {
    inScreen: true
}

export default LoadingScreen;
