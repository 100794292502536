// Global Omnia Application Settings
import { defaultNavigationConfig } from "src/omnia/components/layouts/dashboard/config";

export const APP_SETTING = {

  /**
   * Domain Setup
   *
   * Specify the core domain and protocol of the application
   */
  domain: "demo.groon.io",
  protocol: "https",
  socket_protocol: "wss",
  // domain: "localhost:8000",
  // protocol: "http",
  // socket_protocol: "ws",

  /**
   * Sidebar
   *
   * Specify the sidebar items that shall be in the menu of this instance
   * Example:
   *      {
   *          title: 'Some Tool',
   *          path: '/custom-module/some-tool',
   *          icon: <FittingUntitledIcon />,
   *          items: [ ...children of the same type],
   *      },
   */
  navigation: [
    defaultNavigationConfig.home,
    defaultNavigationConfig.creativeStudio,
    {
      title: 'layout.company',
      icon: 'Building01',
      path: null,
      isModules: true,
      items: [
        defaultNavigationConfig.analytics,
        defaultNavigationConfig.intelligence,
        defaultNavigationConfig.resources,
        defaultNavigationConfig.connections,
        defaultNavigationConfig.projects,
        defaultNavigationConfig.marketing,
      ]
    },
    // {
    //   subheader: "common.tools",
    //   permissions: [],
    //   items: [
    //     {
    //       title: "Customer Management",
    //       path: "/demo/crm",
    //       icon: 'Announcement01',
    //       items: [
    //         {
    //           title: "Client Profiles",
    //           path: "/demo/crm/client-profiles"
    //         },
    //         {
    //           title: "Communication Tracking",
    //           path: "/demo/crm/communication-tracking"
    //         },
    //         {
    //           title: "Service History",
    //           path: "/demo/crm/service-history"
    //         },
    //         {
    //           title: "Feedback Analysis",
    //           path: "/demo/crm/feedback-analysis"
    //         }
    //       ]
    //     },
    //     {
    //       title: "Project Workflow",
    //       path: "/demo/pm",
    //       icon: 'NotificationBox',
    //       items: [
    //         {
    //           title: "Task Assignment",
    //           path: "/demo/pm/task-assignment"
    //         },
    //         {
    //           title: "Progress Tracking",
    //           path: "/demo/pm/progress-tracking"
    //         },
    //         {
    //           title: "Milestone Reporting",
    //           path: "/demo/pm/milestone-reporting"
    //         },
    //         {
    //           title: "Resource Allocation",
    //           path: "/demo/pm/resource-allocation"
    //         }
    //       ]
    //     },
    //     {
    //       title: "Financial Operations",
    //       path: "/demo/financial",
    //       icon: 'Safe',
    //       items: [
    //         {
    //           title: "Invoice Generation",
    //           path: "/demo/financial/invoice-generation"
    //         },
    //         {
    //           title: "Expense Tracking",
    //           path: "/demo/financial/expense-tracking"
    //         },
    //         {
    //           title: "Budget Forecasting",
    //           path: "/demo/financial/budget-forecasting"
    //         },
    //         {
    //           title: "Revenue Analysis",
    //           path: "/demo/financial/revenue-analysis"
    //         }
    //       ]
    //     },
    //     {
    //       title: "Marketing Suite",
    //       path: "/demo/marketing",
    //       icon: 'Palette',
    //       items: [
    //         {
    //           title: "Campaign Planning",
    //           path: "/demo/marketing/campaign-planning"
    //         },
    //         {
    //           title: "Social Media Integration",
    //           path: "/demo/marketing/social-media-integration"
    //         },
    //         {
    //           title: "Lead Generation",
    //           path: "/demo/marketing/lead-generation"
    //         },
    //         {
    //           title: "Analytics Dashboard",
    //           path: "/demo/marketing/analytics-dashboard"
    //         }
    //       ]
    //     },
    //     {
    //       title: "Employee Hub",
    //       path: "/demo/employee-hub",
    //       icon: 'Heart',
    //       items: [
    //         {
    //           title: "Personal Information",
    //           path: "/demo/employee-hub/personal-information"
    //         },
    //         {
    //           title: "Performance Reviews",
    //           path: "/demo/employee-hub/performance-reviews"
    //         },
    //         {
    //           title: "Training Modules",
    //           path: "/demo/employee-hub/training-modules"
    //         },
    //         {
    //           title: "Leave Management",
    //           path: "/demo/employee-hub/leave-management"
    //         }
    //
    //       ]
    //     }
    //   ]
    // }
  ],

  /**
   * Widgets
   *
   * Specify the widgets that shall be available in the dashboards of this instance
   * Example:
   *      {
   *          name: "Custom Widget",
   *          description: "This is a custom widget that does something",
   *          identifier: "UNIQUE-IDENTIFIER",
   *          module: "Custom Module",
   *          component: ReactComponentToUse
   *      },
   */
  widgets: []

};
