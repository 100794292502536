import { useEffect, useRef, useState } from "react";
import useOmniaApi from "./use-omnia-api";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { addEndpointToList, removeEndpointFromList } from "../store/actions/datatable-actions";

function UsePagination(props) {
    /**
     * @name: usePagination
     * @description: This hook is used to paginate the results of a given endpoint
     * @param endpoint: string
     * @param page: number
     * @param append: boolean, new elements will be added when true
     * @param query: object
     * @param pageSize: number
     * @return {{elements: [], pages: number, count: number, loading: boolean}}
     */

    const {
        endpoint,
        page,
        query = {},
        pageSize = 10,
        append = false,
        updateListener = null,
    } = props;

    const { get } = useOmniaApi();
    const [elements, setElements] = useState([]);
    const [pages, setTotalPages] = useState(1);
    const [count, setCount] = useState(0);
    const dispatch = useDispatch();
    const requestIdRef = useRef(0);

    const isMore = pages > page && elements.length > 0;

    const [initialLoading, setInitialLoading] = useState(true);
    const [loading, setLoading] = useState(true);

    const updates = useSelector((state) => state.datatable.updates);

    // Store previous values to compare
    const prevConfigRef = useRef({
        endpoint: null,
        page: null,
        pageSize: null,
        query: null,
        updateTimestamp: null,
    });

    const updateTimestamp = updates?.[updateListener || endpoint];

    const loadData = (reset, withLoading) => {

        const currentRequestId = ++requestIdRef.current;

        if (withLoading) setLoading(true);

        const finalPage = pages === 0 ? 1 : pages;

        const queryConfig = {
            ...query,
            page: reset
                ? 1
                : page > pages
                    ? finalPage
                    : page === 0
                        ? 1
                        : page,
            size: pageSize,
        };

        get(endpoint, queryConfig)
            .then((response) => {
                if (currentRequestId === requestIdRef.current) {
                    setCount(response.count);

                    if (append && !reset) {
                        setElements((prev) =>
                            prev.concat(
                                response.results.filter((r) => !prev.map((p) => p.id).includes(r.id))
                            )
                        );
                    } else {
                        setElements(response.results);
                    }

                    setTotalPages(Math.ceil(response.count / pageSize));
                }
            })
            .finally(() => {
                if (currentRequestId === requestIdRef.current) {
                    setLoading(false);
                    setInitialLoading(false);
                }
            });
    };

    const reload = () => {
        loadData(false, true);
    };

    const reset = () => {
        loadData(true, true);
    };

    useEffect(() => {
        dispatch(addEndpointToList(endpoint));
        return () => {
            dispatch(removeEndpointFromList(endpoint));
        };
    }, [dispatch, endpoint]);

    useEffect(() => {
        const prevConfig = prevConfigRef.current;
        const currentConfig = {
            endpoint,
            page,
            pageSize,
            query,
        };

        const configChanged =
            prevConfig.endpoint !== endpoint ||
            prevConfig.page !== page ||
            prevConfig.pageSize !== pageSize ||
            JSON.stringify(prevConfig.query) !== JSON.stringify(query);

        if (configChanged) {
            loadData(false, true);
            prevConfigRef.current = currentConfig;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endpoint, page, pageSize, query]);

    useEffect(() => {
        const prevUpdateTimestamp = prevConfigRef.current.updateTimestamp;
        if (updateTimestamp && updateTimestamp !== prevUpdateTimestamp) {
            // Load the details
            loadData(true, false);
            prevConfigRef.current.updateTimestamp = updateTimestamp;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateTimestamp]);

    return {
        elements,
        isMore,
        pages,
        count,
        loading,
        initialLoading,
        setElements,
        reload,
        reset,
    };
}

UsePagination.propTypes = {
    page: PropTypes.number.isRequired,
    endpoint: PropTypes.string.isRequired,
    query: PropTypes.object,
    pageSize: PropTypes.number,
    updateListener: PropTypes.string,
};

UsePagination.defaultProps = {
    query: {},
    pageSize: 10,
    updateListener: null,
};

export default UsePagination;
