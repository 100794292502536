import {useTheme} from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {useSettings} from "./use-settings";

export const useIsMobile = () => {
    const theme = useTheme();
    const location = useLocation();
    const settings = useSettings();
    const [ navBarMode, setNavBarMode ] = useState('topbar');

    const fullscreenPathnames = [
        '/groon/chat',
        '/groon/analytics/datahub',
        '/groon/resources/orders',
        '/groon/resources/invoices',
        '/groon/projects/add'
    ];

    // Checks whether the current path is a fullscreen view
    const isFullWidthView = fullscreenPathnames.filter(fp => location.pathname?.includes(fp))?.length > 0;

    // Mobile screen sizes are typically below 'sm'
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Tablet screen sizes are typically between 'sm' and 'md'
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    // Desktop screen sizes are typically above 'md'
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    // Large desktops are above xl
    const isLargeDesktop = useMediaQuery((theme) => theme.breakpoints.up('xl'))

    // Shortcut variable for existing uses
    const isFlying = navBarMode === 'flying';

    useEffect(() => {

        if(isMobile){
            setNavBarMode('mobile');
        } else {

            if(settings?.layout === 'vertical'){
                setNavBarMode('topbar');
            } else {
                if(isFullWidthView){
                    if(isDesktop){
                        setNavBarMode('flying-topbar');
                    } else {
                        setNavBarMode('topbar');
                    }
                } else {
                    if(isLargeDesktop){
                        setNavBarMode('flying');
                    } else if(isDesktop){
                        setNavBarMode('flying-topbar');
                    } else {
                        setNavBarMode('topbar');
                    }
                }
            }

        }
    }, [isFullWidthView, isLargeDesktop, isDesktop, isMobile]);

    return { isMobile, isTablet, isDesktop, isFlying, navBarMode }
}