import {useCallback, useEffect, useState, useMemo} from "react";
import axios from "axios";
import {useNotifications} from "./use-notifications";
import {APP_SETTING} from "src/setup";
import {logRenderingError} from "../store/actions/client-actions";
import {useDispatch} from "react-redux";
import useLocation from "src/omnia/hooks/use-location";
import capitalizeFirstLetter from "../utils/calitalize-first-letter";
import authService from "../services/auth-service";
import {useAppMode} from "./use-app-mode";

const DEFAULT_PAGE_SIZE = 750;

const useApi = (showErrors = true, onError = null, authorize = true, authHeader = null, fileFields = ['files', 'file']) => {

    // hook setup and attributes
    const [errors, setErrors] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadStatus, setUploadStatus] = useState(0);
    const dispatch = useDispatch();
    const location = useLocation();
    const { notify } = useNotifications();
    const {isService} = useAppMode();
    const [requestLog, setRequestLog] = useState({});

    const t = (test) => {
        return test;
    }

    const onUploadProgress = (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setUploadStatus(percentCompleted);
    }

    useEffect(() => {

        // check if errors exist
        if(errors.length > 0){
            // iterate through errors and flash them
            for(let i = 0; i < errors.length; i++){
                if(typeof(showErrors) === "undefined" || showErrors){
                    switch (errors[i].status) {
                        case 500:
                            notify(t("That failed"), 'error');
                            break;
                        case 403:
                            notify(errors[i].message, 'error');
                            break;
                        case 405:
                            notify(t("Not possible"), 'error');
                            break;
                        case 400:
                            notify(t(errors[i].message), 'error');
                            break;
                        case 404:
                            notify(t("Not found"), 'error');
                            break;
                        default:
                            notify(t(errors[i]?.message || "An error has occurred"), 'error');
                            break;
                    }
                    console.log('Code ' + errors[i].status + ': ' + errors[i].message);
                }
            }
            // remove all errors
            setErrors([]);
        }

    }, [errors, showErrors]);

    // helper methods

    const axiosInstance = useMemo(() => {
        const instance = axios.create({
            baseURL: "/api",
            timeout: 100000,
        });

        instance.interceptors.request.use(
            (config) => {
                if (authorize) {
                    const tokenName = isService ? "serviceToken" : "accessToken";
                    const token = authHeader ? authHeader : localStorage.getItem(tokenName);
                    if (token) {
                        config.headers["Authorization"] = token;
                    }
                }
                return config;
            },
            (error) => {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.detail === "Invalid token."
                ) {
                    if (authorize && !authHeader) {
                        authService.logout();
                    }
                }
                return Promise.reject(error);
            }
        );

        return instance;
    }, [authorize, authHeader, isService]);

    function logAndSyncError(error){

        let errorInstance = {
            message: error.message + ' (' + error.code + ')',
            url: (typeof error.request === "undefined") ? "Undefined" : error.request.responseURL,
            path: location.pathname + location.hash,
            method: (typeof error.config === "undefined") ? "Undefined" : error.config.method,
            // dataUp: (typeof error.config === "undefined") ? "Undefined" : ((typeof error.config.details !== "undefined") ? JSON.parse(error.config.details) : null),
            // dataDown: (typeof error.config === "undefined") ? "Undefined" : extractErrors(error, error.response.details, false),
        }

        // Log this error
        dispatch(logRenderingError(error.message, JSON.stringify(errorInstance, null, "\t"), "GROON API (" + ((typeof error.response === "undefined") ? "Hard Crash" : error.response.status) + ")"));

    }

    function loadErrorsFromResponse(error){

        // Check if this error is a network error
        if(error?.message === 'Network Error'){
            return [{status: 999, message: 'Network Error'}];

            // The error is a real API error
        } else {
            if ((error?.response?.headers?.['content-type'] || 'None') === 'application/json') {
                return extractErrors(error?.response?.status || 500, error?.response?.data || 'Something Crashed');
            } else {
                return extractErrors(error?.response?.status || 500, error?.response?.statusText || 'Something Crashed');
            }

        }

    }

    function getAllErrorMessages(error){

        // Check if this error is a network error
        if(error?.message === 'Network Error'){
            setErrors([{status: 999, message: 'Network Error'}]);

        // The error is a real API error
        } else {
            if ((error?.response?.headers?.['content-type'] || 'None') === 'application/json') {
                setErrors(extractErrors(error?.response?.status || 500, error?.response?.data || 'Something Crashed'));
            } else {
                setErrors(extractErrors(error?.response?.status || 500, error?.response?.statusText || 'Something Crashed'));
            }

            // Sync the error with on
            logAndSyncError(error);
        }

    }

    function extractErrors(status, data, callbackActive = true){

        if(typeof(data) === "string"){
            // Call on error method for each single error
            if(onError && callbackActive)
                onError(data);
            // append error
            return [{
                status: status,
                message: data
            }];
        } else {
            let errors = [];
            // get keys
            if(typeof data === "undefined"){
                return "Something crashed";
            } else {
                let keys = Object.keys(data);
                let errorMessage;
                // iterate through sub keys
                for(let i = 0; i < keys.length; i++){
                    if(isNaN(parseFloat(keys[i]))){
                        // Error with index
                        if(keys[i] === 'detail'){
                            errorMessage = data[keys[i]];
                        } else {

                            // Check if extractErrors is an array
                            if (Array.isArray(data[keys[i]])) {

                                // Join all strings in data[keys[i]] with a ". "
                                try {
                                    errorMessage = capitalizeFirstLetter(keys[i]) + ' konnte nicht validiert werden: ' + data[keys[i]]?.join('. ');
                                } catch (e) {
                                    errorMessage = t('Einige Felder konnten nicht validiert werden');
                                }

                            }

                            // Check if extractErrors is an object
                            else if (typeof data[keys[i]] === 'object') {

                                // Get a list with all keys of the object
                                const objectKeys = Object.keys(data);

                                // Format those keys comma separated
                                const objectKeysString = objectKeys.join(', ');
                                errorMessage = capitalizeFirstLetter(capitalizeFirstLetter(keys[i]) + ' konnte nicht validiert werden: ' + objectKeysString);
                            }

                            // Else
                            else {
                                // errorMessage = t('notify.field_failed_to_validate', {field: capitalizeFirstLetter(keys[i])}) + ': ' + data[keys[i]];
                                errorMessage = capitalizeFirstLetter(keys[i]) + ' konnte nicht validiert werden: ' + data[keys[i]];
                            }

                        }
                    } else {
                        // Error without index
                        errorMessage = data[keys[i]];
                    }

                    // Set the errors
                    // const errorMessage = isNaN(parseFloat(keys[i])) ? capitalizeFirstLetter(keys[i]) + ': ' + details[keys[i]] : details[keys[i]];
                    errors = errors.concat(extractErrors(status, errorMessage, callbackActive));
                }
                // return errors
                return errors;
            }
        }
    }

    const getUrlFromData = useCallback((endpoint, query = {}, id = null) => {
        // initiate the url
        let url = APP_SETTING.protocol + "://" + APP_SETTING.domain + '/api';
        // check if dots are contained in endpoint
        if( (typeof(endpoint) === "string") && (!endpoint.includes('.')) ){
            // prepend slash if missing
            if(endpoint[0] !== '/')
                endpoint = '/' + endpoint;
            // append slash if missing
            if(endpoint[endpoint.length - 1] !== '/')
                endpoint = endpoint + '/';

            // check if id is given and append to url
            if(id !== null){
                switch(typeof(id)) {
                    case "string":
                        // check if string contains weird stuff
                        if(isNaN(id))
                            // raise error (id not a number)
                            throw new Error("Error: contact moin@groon.io");
                        endpoint += id + '/';
                        break;
                    case "number":
                        // append id to url
                        endpoint += id.toString() + '/';
                        break;
                    default:
                        // raise error
                        throw new Error("Error: contact moin@groon.io");
                }

                // append slash if missing
                if(endpoint[endpoint.length - 1] !== '/')
                    endpoint = endpoint + '/';
            }

            // set url to working endpoint
            url += endpoint;
            // append query to url
            url += getQueryString(query);
            // return the complete url
            return url;
        } else {
            // return null
            throw new Error("Error: contact moin@groon.io");
        }
    }, []);

    const getQueryString = useCallback((query) => {
        // initiate endpoint local variable
        let query_string = '';
        // create running index
        let counter = 0;
        // check query and create if exists
        if(Object.keys(query).length > 0){
            // iterate through query objects
            Object.keys(query).forEach(function(q){
                // in first run append question mark, otherwise append ampersand
                query_string += (counter === 0) ? '?' : '&';
                // check if query is an empty array
                // note: this is important for the API, and especially the __in filter,
                // otherwise the filter will see an empty string and return all objects
                if(Array.isArray(query[q]) && query[q].length === 0){
                    // Todo: we could use a different encoding here
                    // set query to empty list
                    query[q] = '[]';
                }
                // append query to string
                query_string += q + '=' + query[q];
                // increment counter
                counter += 1;
            })
        }
        // return transformed string
        return query_string;
    }, []);

    function getWholeList(endpoint, query = {}, page = 1){
        // create local query object with current page
        let local_query = query;
        // append the current page to the query
        local_query['page'] = page;
        // perform API request
        return axiosInstance
            .get(getUrlFromData(endpoint, local_query))
            .then((response) => {
                // check if there is another page
                if(response.data.next != null){
                    // return the full content via recursive calls
                    return getWholeList(endpoint, query, page + 1).then((data) => {
                        // concat everything from above to one details package
                        return data.concat(response.data.results);
                    });
                } else {
                    // final recursion
                    return response.data.results;
                }
            })
            .catch((error) => {
                // append error
                setErrors(prev => prev.concat([error]));
                // return empty list
                return [];
            })
    }

    function isListResponse(response){
        // initiate status
        let status = false;
        // check if response is simple (only then could be list)
        if(typeof(response.data) === "object"){
            // check if element-content of list are contained in response
            let has_count = 'count' in response.data;
            let has_next = 'next' in response.data;
            let has_prev = 'previous' in response.data;
            let has_results = 'results' in response.data;
            // check if this is given
            if(has_count && has_next && has_prev && has_results)
                // set status to true
                status = true
        }
        // return operation result
        return status;
    }

    function transformDataToFormData(data){

        // initiate form details
        let fd = null;
        // check if details is object
        if(typeof(data) === "object"){
            const containsFiles = fileFields.some(field => Object.keys(data).includes(field));
            // check if file(s) is passed and switch to form details
            if(containsFiles){
                // create form details element and transform details to it
                fd = new FormData();
                let keys = Object.keys(data);
                for(let i = 0; i < keys.length; i++){
                    if(fileFields.includes(keys[i]) && Array.isArray(data[keys[i]])){
                        // append all files-overview to form
                        for(let j = 0; j < data[keys[i]].length; j++){
                            fd.append(keys[i] + "[" + j + "]", data[keys[i]][j]);
                        }
                    } else if (fileFields.includes(keys[i])) {
                        // add file to form details
                        fd.append(keys[i], data[keys[i]]);
                    } else {

                        // Check if this field is an object
                        if (typeof data[keys[i]] === 'object') {
                            console.log('GROON will transform ' + keys[i] + ' to a JSON string');
                            fd.append(keys[i], JSON.stringify(data[keys[i]]));
                        } else if (typeof data[keys[i]] === 'boolean') {
                            fd.append(keys[i], data[keys[i]]);
                        } else if (typeof data[keys[i]] === 'number') {
                            fd.append(keys[i], data[keys[i]]);
                        } else if (typeof data[keys[i]] === 'string') {
                            fd.append(keys[i], data[keys[i]]);
                        }

                    }
                }
            } else {
                fd = data;
            }

            // return transformed dataset
            return fd;
        }
        return {};
    }

    function appendRequestToLog(endpoint, feedback){

        if(isListResponse(feedback)){
            setRequestLog(prev => {
                prev[endpoint] = feedback.data.count;
                return prev;
            });
        } else {
            setRequestLog(prev => {
                prev[endpoint] = null;
                return prev;
            });
        }

    }

    // specialized access points

    const post = (endpoint, data= {}, args = {}) => {
        let defaultConfig = {onUploadProgress: onUploadProgress};
        let config = {...defaultConfig, ...args}
        return new Promise((resolve, reject) => {
            setUploadStatus(0);
            setIsUploading(true);
            axiosInstance
                .post(getUrlFromData(endpoint), transformDataToFormData(data), config)
                .then((response) => {
                    // check response status (201 is created) or (200 is ok)
                    if((response.status === 201) || (response.status === 200)){
                        // return passed details
                        resolve(response.data);
                    } else {
                        // return null
                        reject(['SDK error with HTTP status ' + response.status]);
                    }
                })
                .catch((error) => {
                    // handle errors
                    reject(loadErrorsFromResponse(error));
                    // get all errors
                    getAllErrorMessages(error);
                })
                .finally(() => {
                    setUploadStatus(0);
                    setIsUploading(false);
                });
        });
    }

    const patch = (endpoint, id, data, args = {}) => {

        let defaultConfig = {onUploadProgress: onUploadProgress};
        let config = {...defaultConfig, ...args}
        return new Promise((resolve, reject) => {

            setUploadStatus(0);
            setIsUploading(true);

            // transform endpoint (in rare case that id is null)
            let transformedEndpoint = (id === null) ? endpoint : endpoint + '/' + id ;
            // perform request
            axiosInstance
                .patch(getUrlFromData(transformedEndpoint), transformDataToFormData(data), config)
                .then((response) => {
                    // check response status (201 is created)
                    if((response.status === 201) || (response.status === 200)){
                        // return passed details
                        resolve(response.data);
                    } else {
                        // reject
                        reject(['SDK error with status ' + response.status]);
                    }
                })
                .catch((error) => {
                    // get all errors
                    getAllErrorMessages(error);
                    // handle errors
                    reject(loadErrorsFromResponse(error));
                })
                .finally(() => {
                    setUploadStatus(0);
                    setIsUploading(false);
                });
        });
    }

    const getFile = (file) => {
        return new Promise((resolve, reject) => {
            axiosInstance(getUrlFromData('core/files/' + file['id'] + '/view'), {method: 'GET', responseType: 'blob'})
                .then(response => {
                    if(response.status === 200){
                        resolve(response.data);
                    } else {
                        reject(null);
                    }
                })
                .catch(() => {
                    reject(null);
                })
        })
    }

    // regular access points

    const getCount = (endpoint, query={}) => {

        return new Promise((resolve, reject) => {
            if(Object.keys(requestLog).includes(endpoint)){
                resolve(requestLog[endpoint]);
            } else {
                // add needed params to query
                query['size'] = 1;
                query['page'] = 1;
                // perform axios request
                axiosInstance.get(getUrlFromData(endpoint, query))
                    .then((response) => {
                        if (response.status === 200) {
                            if (isListResponse(response)) {
                                resolve(response['data']['count']);
                            }
                        }
                        // in this case it is no list response
                        resolve(null);
                    })
                    .catch(() => {
                        if(showErrors)
                            notify(t("An error has occurred"), "error");
                        reject();
                    });
            }
        });
    }

    const get = useCallback((endpoint, query = {}) => {
        return new Promise((resolve, reject) => {

            // create final query object for request
            if(typeof(query['size']) === "undefined")
                query['size'] = DEFAULT_PAGE_SIZE;

            // Determine the page
            const page = (query && typeof query.page !== 'undefined') ? query.page : null;

            // perform request
            axiosInstance.get(getUrlFromData(endpoint, query))
                .then((response) => {

                    // check response status
                    if(response.status === 200){
                        // Save KPIs about the request
                        appendRequestToLog(endpoint, response);
                        // check if response is list
                        if(isListResponse(response)){
                            // if page is null get everything
                            if(page === null){
                                // get list content when there are items
                                if(response.data.count > 0){

                                    if(response.data.next !== null){
                                        // TODO: we can directly use the next link
                                        getWholeList(endpoint, query)
                                            .then((data) => {
                                                // return the details result
                                                resolve(data);
                                            })
                                            .catch(() => {
                                                reject(['SDK error while getting details from list.']);
                                            })

                                    } else {
                                        resolve(response.data.results);
                                    }

                                } else {
                                    resolve([]);
                                }


                            } else {
                                // Resolve using a different approach to show how many there are etc.
                                resolve({
                                    results: response.data.results,
                                    count: response.data.count,
                                    page: page,
                                    next: response.data.next,
                                });
                            }
                        } else {
                            // get contained details
                            resolve(response.data);
                        }
                    } else {
                        // return null
                        reject(['SDK error with status ' + response.status]);
                    }
                })
                .catch((error) => {
                    // get all errors
                    getAllErrorMessages(error);
                    // return nothing in error case
                    reject(loadErrorsFromResponse(error));
                });
        });
    }, [axiosInstance, getUrlFromData]);

    const put = useCallback((endpoint, data) => {
        // check if incoming details is object
        if(typeof(data) == 'object'){
            // check if this shall be a post or patch request
            if(typeof(data.id) === "undefined"){
                // post request for creating new object
                return post(endpoint, data);
            } else {
                // patch request for updating an existing object
                return patch(endpoint, data.id, data);
            }
        } else {
            // post request for creating new object
            return post(endpoint, {});
        }
    }, [axiosInstance, getUrlFromData])

    const opt = useCallback((endpoint) => {
        return new Promise((resolve, reject) => {
            axiosInstance
                .options(getUrlFromData(endpoint))
                .then((response) => {
                    if(response.status === 200){
                        // check if response is list
                        resolve((typeof(response.data.actions) !== "undefined") ? response.data.actions : response.data);
                    } else {
                        // return null
                        reject(['SDK error with status ' + response.status]);
                    }
                })
                .catch((error) => {
                    // get all errors
                    getAllErrorMessages(error);
                    // handle errors
                    reject(loadErrorsFromResponse(error));
                });
        });
    }, [axiosInstance, getUrlFromData])

    const del = useCallback((endpoint, toDelete, query = {}) => {
        return new Promise((resolve, reject) => {
            let urlEnding, url, params;

            // Check if given id is array object
            if (Array.isArray(toDelete)) {

                // Mass delete
                urlEnding = endpoint + '/mass_delete';
                url = getUrlFromData(urlEnding);
                params = { data: { ids: toDelete } };

            } else {

                // Check whether toDelete is a string or number. If object, check whether it has an id property
                if(toDelete !== null){
                    if(typeof(toDelete) === "object"){
                        if(typeof(toDelete.id) !== "undefined"){
                            urlEnding = '/' + toDelete.id;
                        } else {
                            reject(['SDK error: toDelete object does not have an id property']);
                        }
                    } else if(typeof(toDelete) !== "undefined"){
                        urlEnding = '/' + toDelete;
                    }
                } else {
                    urlEnding = '';
                }

                // Single delete
                url = getUrlFromData(endpoint + urlEnding, query);
                params = {};

            }

            // Perform axios call
            axiosInstance
                .delete(url, params)
                .then(response => {
                    // Check response code
                    if (response.status === 200 || response.status === 204) {
                        resolve(response.data);
                    } else {
                        reject(['SDK error with status ' + response.status]);
                    }
                })
                .catch(error => {
                    // Retrieve all error messages
                    getAllErrorMessages(error);
                    // Handle errors
                    reject(loadErrorsFromResponse(error));
                });
        });
    }, [axiosInstance, getUrlFromData]);

    // Wrap the returned functions in useMemo
    const apiMethods = useMemo(() => {
        return {
            get,
            put,
            opt,
            del,
            post,
            patch,
            getCount,
            getFile,
            getUrlFromData,
            uploadStatus,
            isUploading,
            onError,
        };
    }, [
        get,
        put,
        opt,
        del,
        post,
        patch,
        getCount,
        getFile,
        getUrlFromData,
        uploadStatus,
        isUploading,
        onError,
    ]);

    return apiMethods;
}

export default useApi;