import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Drawer, Stack} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {Scrollbar} from 'src/omnia/components/elements/scrollbar';
import {usePathname} from 'src/omnia/hooks/use-pathname';
import {TenantSwitch} from '../tenant-switch';
import {useTranslation} from "react-i18next";
import CreateUserRequest from "../../../../components/modules/core/create-user-request";
import {SideNavSection} from "../flying-layout/side-nav-section";
import {useSettings} from "../../../../hooks/use-settings";

const MOBILE_NAV_WIDTH = 230;

const useCssVars = (color) => {
    const theme = useTheme();

    return useMemo(() => {
        switch (color) {
            // Blend-in and discreet have no difference on mobile because
            // there's a backdrop and differences are not visible
            case 'blend-in':
            case 'discreet':
                if (theme.palette.mode === 'dark') {
                    return {
                        '--nav-bg': theme.palette.background.default,
                        '--nav-color': theme.palette.neutral[100],
                        '--nav-logo-border': theme.palette.neutral[700],
                        '--nav-section-title-color': theme.palette.neutral[400],
                        '--nav-item-color': theme.palette.neutral[400],
                        '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
                        '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
                        '--nav-item-active-color': theme.palette.text.primary,
                        '--nav-item-disabled-color': theme.palette.neutral[600],
                        '--nav-item-icon-color': theme.palette.neutral[500],
                        '--nav-item-icon-active-color': theme.palette.primary.main,
                        '--nav-item-icon-disabled-color': theme.palette.neutral[700],
                        '--nav-item-chevron-color': theme.palette.neutral[700],
                        '--nav-scrollbar-color': theme.palette.neutral[400]
                    };
                } else {
                    return {
                        '--nav-bg': theme.palette.background.default,
                        '--nav-color': theme.palette.text.primary,
                        '--nav-logo-border': theme.palette.neutral[100],
                        '--nav-section-title-color': theme.palette.neutral[400],
                        '--nav-item-color': theme.palette.text.secondary,
                        '--nav-item-hover-bg': theme.palette.action.hover,
                        '--nav-item-active-bg': theme.palette.action.selected,
                        '--nav-item-active-color': theme.palette.text.primary,
                        '--nav-item-disabled-color': theme.palette.neutral[400],
                        '--nav-item-icon-color': theme.palette.neutral[400],
                        '--nav-item-icon-active-color': theme.palette.primary.main,
                        '--nav-item-icon-disabled-color': theme.palette.neutral[400],
                        '--nav-item-chevron-color': theme.palette.neutral[400],
                        '--nav-scrollbar-color': theme.palette.neutral[900]
                    };
                }
            case 'evident':
                if (theme.palette.mode === 'dark') {
                    return {
                        '--nav-bg': theme.palette.neutral[800],
                        '--nav-color': theme.palette.common.white,
                        '--nav-logo-border': theme.palette.neutral[700],
                        '--nav-section-title-color': theme.palette.neutral[400],
                        '--nav-item-color': theme.palette.neutral[400],
                        '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
                        '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
                        '--nav-item-active-color': theme.palette.common.white,
                        '--nav-item-disabled-color': theme.palette.neutral[500],
                        '--nav-item-icon-color': theme.palette.neutral[400],
                        '--nav-item-icon-active-color': theme.palette.primary.main,
                        '--nav-item-icon-disabled-color': theme.palette.neutral[500],
                        '--nav-item-chevron-color': theme.palette.neutral[600],
                        '--nav-scrollbar-color': theme.palette.neutral[400]
                    };
                } else {
                    return {
                        '--nav-bg': theme.palette.neutral[800],
                        '--nav-color': theme.palette.common.white,
                        '--nav-logo-border': theme.palette.neutral[700],
                        '--nav-section-title-color': theme.palette.neutral[400],
                        '--nav-item-color': theme.palette.neutral[400],
                        '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
                        '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
                        '--nav-item-active-color': theme.palette.common.white,
                        '--nav-item-disabled-color': theme.palette.neutral[500],
                        '--nav-item-icon-color': theme.palette.neutral[400],
                        '--nav-item-icon-active-color': theme.palette.primary.main,
                        '--nav-item-icon-disabled-color': theme.palette.neutral[500],
                        '--nav-item-chevron-color': theme.palette.neutral[600],
                        '--nav-scrollbar-color': theme.palette.neutral[400]
                    };
                }
            default:
                return {};
        }
    }, [theme, color]);
};

export const MobileNav = (props) => {
    const { color = 'evident', open, onClose, sections = [] } = props;
    const pathname = usePathname();
    const settings = useSettings();
    const cssVars = useCssVars(color);

    const visionLayout = settings.layout !== 'vertical';

    return (
        <Drawer
            anchor="left"
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    ...cssVars,
                    backgroundColor: 'var(--nav-bg)',
                    color: 'var(--nav-color)',
                    margin: visionLayout ? 2 : 0,
                    maxHeight: visionLayout ? 'calc(100% - 30px)' : '100%',
                    width: MOBILE_NAV_WIDTH,
                    borderRadius: visionLayout ? 2 : 0,
                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                    overflow: 'hidden',
                },
            }}
            variant="temporary"
        >
            <Scrollbar
                sx={{
                    height: '100%',
                    '& .simplebar-content': {
                        height: '100%',
                    },
                    '& .simplebar-scrollbar:before': {
                        background: 'var(--nav-scrollbar-color)',
                    },
                }}
            >
                <Stack sx={{ height: '100%' }}>
                    <Stack
                        alignItems="center"
                        direction="column"
                        justifyContent="center"
                        spacing={2}
                        sx={{ p: 3 }}
                    >
                        <TenantSwitch sx={{ flexGrow: 1 }} />
                    </Stack>
                    <Stack
                        component="nav"
                        spacing={2}
                        sx={{
                            flexGrow: 1,
                            px: 2,
                        }}
                    >
                        {sections.map((section, index) => (
                            <SideNavSection
                                items={section.items}
                                key={index}
                                pathname={pathname}
                                title={section.title}
                            />
                        ))}
                    </Stack>
                    <CreateUserRequest />
                </Stack>
            </Scrollbar>
        </Drawer>
    );
};

MobileNav.propTypes = {
    color: PropTypes.oneOf(['blend-in', 'discreet', 'evident']),
    onClose: PropTypes.func,
    open: PropTypes.bool,
    sections: PropTypes.array
};
